<template>
  <div>
    <div class="text-right bulkquickactions">
      <v-tooltip right v-if="!showMRIcon">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon color="#76D296" v-bind="attrs" v-on="on" @click="takeQuickAction('accept')">
            <v-icon>mdi-check-circle</v-icon>
          </v-btn>
        </template>
        <span>Accept</span>
      </v-tooltip>
      <v-menu rounded="lg" :close-on-content-click="false" v-model="AddToTQPopout" transition="slide-x-transition" right
        v-if="!showMRIcon">
        <template v-slot:activator="{ on: menu, attrs }">
          <v-tooltip right>
            <template v-slot:activator="{ on: tooltip }">
              <v-btn icon color="#FF8842" v-bind="attrs" v-on="{ ...tooltip, ...menu }">
                <v-icon class="" v-bind="attrs" v-on="on">mdi-help-circle</v-icon>
              </v-btn>
            </template>
            <span>Add to TQ</span>
          </v-tooltip>
        </template>
        <v-card class="quickactionspoput">
          <h1>
            <v-icon color="#FF8842">mdi-help-circle</v-icon> Add to TQ
          </h1>
          <textarea name="" v-model="quickActionCommentsTQ" @keyup="commentsTQText" rows="4"
            placeholder="Add your comments and click submit" class="px-2 py-1"></textarea>
          <PackagesDropdown :currentTag="currentTag" @getSelectedTags="getSelectedPackage" v-if="quickData.category == 'packages'"/>
          <TagsDropdown :key="showTagsDropdown" :piping="isPiping" :taglists="taglists" :showEvalIcon="showEvalIcon" :currentTag="currentTag"
            @getSelectedTags="getSelectedTag" :checkedTags="addedTags" v-else/>
          <disciplineDropdown :defaultDiscipline="defaultDiscipline" :selectionType="false" @updateDisciplines="updateDisciplines"></disciplineDropdown>
          <offerDropdown v-if="currentOfferId" key="currentOfferId" :defaultOffer="currentOffer" :selectionType="true" @updateOffers="updateSelectedOffer"></offerDropdown>
          <v-file-input v-model="tqAttachment" multiple chips truncate-length="15" label="Attach file(optional)"
            class=""></v-file-input>
          <small v-if="commentsRequiredTQ.length" class="text-danger">{{ commentsRequiredTQ }}</small>
          <v-card-actions class="px-0">
            <v-spacer></v-spacer>
            <v-btn elevation="0" depressed class="px-4" rounded small @click="closeQuickActionsPoput">
              Cancel
            </v-btn>
            <v-btn elevation="1" :disabled="pageView === 'alt-view' && techSpecTags.length == 0" @click="takeQuickActionTQ('add to tq')" color="#000026" class="text-white px-4" rounded
              small>Submit <v-progress-circular indeterminate color="#ffffff" :size="15" v-if="submitLoad"
                style="margin-left: 10px;"></v-progress-circular></v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>
      <v-menu rounded="lg" :close-on-content-click="false" v-model="IdcPopout" transition="slide-x-transition" right>
        <template v-slot:activator="{ on: menu, attrs }">
          <v-tooltip right>
            <template v-slot:activator="{ on: tooltip }">
              <v-btn icon color="#FDD666" v-bind="attrs" v-on="{ ...tooltip, ...menu }">
                <v-icon class="fa-flip-horizontal" v-bind="attrs" v-on="on">mdi-reply-circle</v-icon>
              </v-btn>
            </template>
            <span>IDC (Assign)</span>
          </v-tooltip>
        </template>
        <v-card class="quickactionspoput">
          <h1>
            <v-icon color="#FDD666" class="fa-flip-horizontal">mdi-reply-circle</v-icon> IDC (Assign)
          </h1>
          <disciplineDropdown @updateDisciplines="updateIDCDisciplines" :isIDC="true" class="discipline-dropdown"></disciplineDropdown>
          <small v-if="!selectedDisciplines.length && disciplineWarning" class="text-danger">Discipline Required</small>
          <textarea name="" v-model="quickActionCommentsAssign" @keyup="commentsAssignText" rows="4"
            :placeholder="category === 'lhs' ? 'Add Query' : 'Add your comments and click submit'" class="px-2 py-1 mt-3"></textarea>
          <small v-if="commentsRequired.length" class="text-danger">{{ commentsRequired }}</small>
          <PackagesDropdown :currentTag="currentTag" @getSelectedTags="getSelectedPackage" v-if="quickData.category == 'packages'"/>
          <TagsDropdown :key="showTagsDropdown" :piping="isPiping" :taglists="taglists" :showEvalIcon="showEvalIcon" :currentTag="pageView === 'alt-view' ? 'currentTag' : currentTag" 
            @getSelectedTags="getSelectedTag" :checkedTags="addedTags" v-else />
          <v-file-input v-model="IDCAttachment" multiple chips truncate-length="15" label="Attach file(optional)"
          class=""></v-file-input>
          <v-card-actions class="px-0">
            <v-spacer></v-spacer>
            <v-btn elevation="0" depressed class="px-4" rounded small @click="closeQuickActionsPoput">
              Cancel
            </v-btn>
            <v-btn elevation="1" :disabled="submitLoad || pageView === 'alt-view' && techSpecTags.length == 0" @click="takeQuickActionAssign('assign')" color="#000026" class="text-white px-4"
              rounded small>Submit <v-progress-circular indeterminate color="#ffffff" :size="15" v-if="submitLoad"
                style="margin-left: 10px;"></v-progress-circular></v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>
      <v-tooltip right v-if="!showMRIcon">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon color="#FFBEB1" v-bind="attrs" v-on="on" @click="takeQuickAction('confirmcde')">
            <v-icon>mdi-close-circle</v-icon>
          </v-btn>
        </template>
        <span>Non-Compliant</span>
      </v-tooltip>
      <v-menu rounded="lg" :close-on-content-click="false" v-if="showMRIcon" v-model="editSpecificationPopout"
        transition="slide-x-transition" right top>
        <template v-slot:activator="{ on: menu, attrs }">
          <v-tooltip right>
            <template v-slot:activator="{ on: tooltip }">
              <v-btn icon color="#6bb6ff" v-bind="attrs" v-on="{ ...tooltip, ...menu }">
                <v-icon class="" v-bind="attrs" v-on="on">mdi-pencil-circle</v-icon>
              </v-btn>
            </template>
            <span>Edit Selected Technical Description</span>
          </v-tooltip>
        </template>
        <v-card class="quickactionspoput">
          <h1>
            <v-icon color="#6bb6ff">mdi-pencil-circle</v-icon> Edit Selected Technical Description
          </h1>
          <textarea name="" v-model="lhsBulkUpdatedValue" rows="4" placeholder="Add your value and click submit"
            class="px-2 py-1"></textarea>
          <textarea type="text" name="" v-model="techspecRemarks" rows="1" class="px-2 py-1"
            style="height:40px; border: 1px solid #ccc; border-radius: 3px;width: 100%;"
            placeholder="Add your remarks" />
          <TagsDropdown :taglists="taglists" :showEvalIcon="showEvalIcon" :currentTag="currentTag"
            @getSelectedTags="getSelectedTag" :checkedTags="addedTags" v-if="!$route.query.package_id"/>
            <PackagesDropdown v-if="$route.query.package_id" :currentTag="currentTag" @getSelectedTags="getSelectedPackage" />
          <v-card-actions class="px-0">
            <v-spacer></v-spacer>
            <v-btn elevation="0" depressed class="px-4" rounded small @click="closeQuickActionsPoput">
              Cancel
            </v-btn>
            <v-btn elevation="1" :disabled="submitLoad || pageView === 'alt-view' && techSpecTags.length == 0" @click="lhsBulkUpdate('update')" color="#000026" class="text-white px-4" rounded
              small>Submit</v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>
      <v-tooltip right v-if="showMRIcon">
        <template v-slot:activator="{ on, attrs }">
          <v-dialog v-model="lhsBulkDeleteDialog" width="500" persistent>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon color="#e55353" v-bind="attrs" v-on="on">
                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on">mdi-delete-circle</v-icon>
                  </template>
                  <span>Delete Selected Technical Description</span>
                </v-tooltip>
              </v-btn>
            </template>
            <v-card class="py-5">
              <v-card-title class="headline">Delete?</v-card-title>
              <v-card-text class="text-dark font-weight-bold mt-3">
                Are you sure you want to delete the selected Technical Description(s)?
                <v-alert dense outlined type="warning" class="mt-3">
                Please check any associated queries in the TQ listing, IDC listing screen and delete, if required.
              </v-alert>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-spacer></v-spacer>
                <v-btn :disabled="submitLoad" rounded elevation="0" color="success" size="sm" @click="lhsBulkUpdate('delete')">Yes <v-progress-circular indeterminate color="white" :size="15" v-if="submitLoad"
                style="margin-left: 10px;"></v-progress-circular></v-btn>
                <v-btn :disabled="submitLoad" rounded elevation="0" color="#e03232" size="sm" @click="lhsBulkDeleteDialog = false"
                  class="text-white">No</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </template>
        <span></span>
      </v-tooltip>
      <!-- <v-tooltip right>
    <template v-slot:activator="{ on, attrs }">
    <v-btn icon color="#6bb6ff" v-bind="attrs" v-on="on" @click="getTagForEval" v-if="showEvalIcon">
    <v-icon>mdi-tag-multiple</v-icon>
  </v-btn>
</template>
<span>Evaluate other tags</span>
</v-tooltip> -->
      <!-- <div v-if="dialogWarning" class="text-danger">Screens - Offer Evaluation | Evaluate other Tags | Detailed offer evaluation</div> -->
    </div>
    <v-dialog v-model="dialogWarning" :max-width="(this.warningList.length > 0)? '950px': '500px'" content-class="vdialognew">
      <v-card class="py-4">
        <div class="d-flex">
          <div class="order-0 pa-3" style="width: 100%;">
            <v-card-title class="error--text pl-3">
              Warning
            </v-card-title>
            <div class="pa-2 pl-3">
              <v-alert dense outlined type="error" class="">
                  There are <b>{{ emptyInferenceCount }}</b> Technical Specification(s) for which the <br>RE-Inference is not
                  recorded.
                </v-alert>
                <p>RE Inference cannot be empty when you click <br><b>
                    <v-icon style="color: #76D296;">mdi-check-circle</v-icon> Accept or
                    <v-icon style="color: #FFBEB1;">mdi-close-circle</v-icon> Non-Compliant
                  </b> on a Technical Specification.
                </p>
                <p>Please record your inferences for the Technical Specifications selected.</p>
            </div>
          </div>
          <div class="order-1 pa-2" v-if="this.warningList.length > 0">
            <div class="pa-3" style="width: 475px; height: 300px; overflow-y: auto; ">
              <v-expansion-panels class="warning-list">
                <v-expansion-panel class="mr-list-item"
                  v-for="(item,i) in warningList"
                  :key="i"
                >
                  <v-expansion-panel-header class="font-weight-bold">{{item.req}}</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <div v-for="(spec, s) in item.tech_spec" :key="s"> {{ s + 1 }}. &nbsp; {{ spec }}</div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>
          </div> 
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn depressed rounded color="#ced2d8" @click="dialogWarning = false;">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="lhsDialogWarning" max-width="500px" content-class="vdialognew">
      <v-card class="py-4">
        <v-card-title class="error--text">
          Warning
        </v-card-title>
        <v-card-text class="py-5">
          <v-alert dense outlined type="error" class="">
            You have not entered any value in the field.
          </v-alert>
          <p>If you click Ok, the values of the Selected Descriptions for the selected tags will be marked blank.</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn depressed rounded color="#26994d" class="text-white"
            @click="lhsBulkUpdate('update', 'Update as a blank')">
            Ok
          </v-btn>
          <v-btn depressed rounded color="#e03232" class="text-white px-5" @click="lhsDialogWarning = false">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div class="notification-cnt">
      <v-alert type="success" v-if="successAlert || successMessage">{{ successMessage }}</v-alert>
      <v-alert type="error" v-if="errorAlert">{{ errorMessage }}</v-alert>
      <v-alert v-if="warningAlert" outlined border="left" prominent type="warning" style="width: 500px;background: #fff !important;">
        Please note that the following technical description(s) is/are not found in other packages of this bid tab. Your action will not be applied to these packages. 
        <div v-for="(l,j) in warningAlertMessage" :key="j">
          <b>{{ j }} :{{l}}</b>
        </div>
      </v-alert>
      <v-alert colored-border elevation="2" v-if="loadingText">
            <v-progress-circular indeterminate color="#0c9577"></v-progress-circular>
            {{ loadingText }}
      </v-alert>
    </div>
    <!-- <div class="tag-list" v-if="tagEval">
      <tag-list @close-popup="close" :popData="cellData" :vendor="vn" :rq="cellData.name" :spec="cellData.technical_description['0']" />
  </div> -->
  </div>
</template>
<script>
import axios from 'axios'
import TagsDropdown from '@/components/Reusable Components/TagsDropdown';
import PackagesDropdown from '@/components/Reusable Components/PackagesDropdown';
import disciplineDropdown from '@/components/Reusable Components/DisciplineDropdown';
import offerDropdown from '@/components/Reusable Components/OfferDropdown'
import { actionNames } from '@/utils/constants';
import { cilWarning } from '@coreui/icons';
export default {
  props: ['quickData', 'vn', 'em', 'lock', 'spec', 'dragDisable', 'showEvalIcon', 'requirementGroupBulk', 'callComp', 'taglists', 'showMRIcon', 'currentTag', 'category', 'defaultDiscipline','pageView'],
  data() {
    return {
      AddToTQPopout: false,
      IdcPopout: false,
      editSpecificationPopout: false,
      quickActionComments: '',
      quickActionCommentsAssign: '',
      quickActionCommentsTQ: '',
      lhsBulkUpdatedValue: '',
      techspecRemarks: '',
      on: '',
      em_ad: [],
      tagEval: false,
      cellData: {},
      updateCellData: {},
      successAlert: false,
      successMessage: '',
      errorAlert: false,
      errorMessage: '',
      name: "tag-list",
      commentsRequired: '',
      assignRequired: '',
      commentsRequiredTQ: '',
      active: true,
      dialogWarning: false,
      enableDialogWarning: false,
      emptyInferenceCount: '',
      lhsBulkDeleteDialog: false,
      lhsDialogWarning: false,
      tqAttachment: [],
      IDCAttachment: [],
      payloadAttachments: [],
      submitLoad: false,
      techSpecTags: [],
      showTagsDropdown:true,
      disciplineWarning: false,
      selectedDisciplines: [],
      selectedDiscipline: [],
      selectedOffers: [],
      currentOffer: [],
      selectedTags:[],
      warningAlert: false,
      warningAlertMessage:{},
      warningList: [],
      loadingText:'',
    }
  },
  components: {
    TagsDropdown,
    disciplineDropdown,
    offerDropdown,
    PackagesDropdown
  },
  computed: {
    addedTags(){
      if(this.pageView === 'alt-view'){
        return this.taglists.filter(tag => !tag.tag_disabled).map(tag => tag.tag_uuid);
      }else{
        return false
      }
    },
    isPiping(){
      return (this.$props.quickData.is_piping)
    },
    currentOfferId () {
      let offerId = this.$props.quickData.parent_offer_id || this.$props.quickData.offer;

      if (Array.isArray(offerId)) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.currentOffer = offerId;
      }else if (typeof offerId === 'string' && offerId.includes(',')){
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.currentOffer = offerId.split(',');
      }else {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.currentOffer = [offerId];
      }
      return offerId;
    },
    showTagDrop() {
      if (this.$route.query.package_id) {
        return true
      } else {
        return false
      }
    }
  },
  watch: {
    quickData(newVal) {
      this.cellData = newVal
      // if (this.$props.quickData.technical_description.hasOwnProperty('offer_id')) {
      //     this.$props.quickData.offer = this.$props.quickData.technical_description.offer_id
      // }
    },
    vn(newVal) {
      if (newVal) {
        this.vendor_name = newVal
      }
    }
  },
  mounted() {
  },
  created() {
  },
  methods: {
    filterData() {
          let temp = {};
          this.warningList = []
          this.$props.quickData.payLoad.forEach((item) => {
            if (item.inferenceCol == '') {
              if (!temp[item.req_group]) {
                  temp[item.req_group] = { req: item.req_group, tech_spec: [] };
                  this.warningList.push(temp[item.req_group]);
              }
              if (!temp[item.req_group].tech_spec.includes(item.tech_spec)) {
                  temp[item.req_group].tech_spec.push(item.tech_spec);
              } 
            }
          }) 
    },
    updateSelectedOffer(offers) {
      this.selectedOffers = offers;
    },
    updateDisciplines(selectedDisciplines) {
      if(selectedDisciplines?.length){
        this.selectedDiscipline = [selectedDisciplines];
      } else {
        this.selectedDiscipline = [];
      }
    },
    getSelectedTag(selected) {
      this.techSpecTags = selected.map(tag => tag.tag_uuid);
    },
    getSelectedPackage(val) {
      this.selectedTags = val.map(pkg => pkg.sub_package_uuid)
    },
    updateIDCDisciplines(selectedDisciplines) {
      this.selectedDisciplines = selectedDisciplines;
    },
    editBulkSpecification() {

    },
    commentsAssignText() {
      if (this.quickActionCommentsAssign.length < 2) {
        this.commentsRequired = 'Comments Required'
      } else {
        this.commentsRequired = ''
      }
    },
    commentsTQText() {
      if (this.quickActionCommentsTQ.length < 2) {
        this.commentsRequiredTQ = 'Comments Required'
      } else {
        this.commentsRequiredTQ = ''
      }
    },
    // lhsBulkUpdatedInput() {
    //     if (this.lhsBulkUpdatedValue.length < 1) {
    //         this.commentsRequiredTQ = 'Add specification value'
    //     } else {
    //         this.commentsRequiredTQ = ''
    //     }
    // },
    closeQuickActionsPoput() {
      this.AddToTQPopout = false
      this.IdcPopout = false
      this.editSpecificationPopout = false
      this.quickActionComments = ''
      this.commentsRequired = ''
      this.assignRequired = ''
      this.quickActionCommentsAssign = ''
      this.quickActionCommentsTQ = ''
      this.commentsRequiredTQ = ''
      this.lhsBulkUpdatedValue = ''
      this.techspecRemarks = ''
      this.techSpecTags = []
      this.showTagsDropdown = !this.showTagsDropdown
      this.tqAttachment = [],
      this.IDCAttachment = [],
      this.payloadAttachments = []
      this.submitLoad = false
    },
    onSelect() {

    },
    downloadFile() {

    },
    takeQuickActionTQ(actionType) {
      if (this.quickActionCommentsTQ.trim().length < 2 && this.quickActionCommentsTQ.trim() === '') {
        this.commentsRequiredTQ = 'Comments Required'
      } else {
        this.quickActionComments = this.quickActionCommentsTQ
        this.handleFileUpload(actionType, this.tqAttachment, 'tq')
        this.submitLoad = true
      }
    },
    handleFileUpload(actionType, attachment, type) {
      if (attachment.length > 0) {
        let queryParams = '';
        if (type == 'idc' && this.$route.query.package_id) {
          queryParams = actionType === actionNames.ASSIGN ? `type=idc&package_id=${this.$route.query.package_id}` : `type=tq&offer_id=${this.$props.quickData.offer || this.$props.quickData.offer_id}`
        } else {
          if (this.$route.query.package_id) {
            if(this.category === 'lhs') {
              queryParams = actionType === actionNames.ASSIGN ? `type=idc&package_id=${this.$route.query.package_id}` : `type=tq&offer_id=${this.$props.quickData.offer || this.$props.quickData.offer_id}`
            } else {
              queryParams = actionType === actionNames.ASSIGN ? `type=idc&package_id=${this.$route.query.item_id || this.$route.query.itemId}&offer_id=${this.$props.quickData.offer || this.$props.quickData.offer_id}` : `type=tq&offer_id=${this.$props.quickData.offer || this.$props.quickData.offer_id}`
            }
          } else {
            if(this.category === 'lhs') {
              queryParams = actionType === actionNames.ASSIGN ? `type=idc&mr_id=${this.$route.query.mr_id}` : `type=tq&offer_id=${this.$props.quickData.offer || this.$props.quickData.offer_id}`
            } else {
              queryParams = actionType === actionNames.ASSIGN ? `type=idc&mr_id=${this.$route.query.item_id || this.$route.query.itemId}&offer_id=${this.$props.quickData.offer || this.$props.quickData.offer_id}` : `type=tq&offer_id=${this.$props.quickData.offer || this.$props.quickData.offer_id}`
            }
          }
        }
        attachment.forEach((files) => {
          var data = new FormData();
          data.append('file', files)
          
          axios.post(`${process.env.VUE_APP_API}/file/upload?${queryParams}`, data, {
            headers: {
              'Access-Control-Allow-Origin': '*'
            },
            processData: false,
            contentType: false,
          })
            .then(response => {
              this.payloadAttachments.push({ file: files.name, file_key: response.data.file })
              if (attachment.length == this.payloadAttachments.length) {
                this.saveCollab(actionType)
              }
            })
            .catch(err => {
              this.tqAttachment = [],
              this.IDCAttachment = [],
              this.payloadAttachments = []
              this.submitLoad = false
              this.error = err?.response?.data?.message
              this.errorAlert = true
              this.errorMessage = err?.response?.data?.message
              setTimeout(() => {
                this.errorAlert = false
              }, 3000)
            })
        })
      } else {
        this.saveCollab(actionType)
      }
    },
    takeQuickActionAssign(actionType) {
      if (!this.selectedDisciplines.length) {
        this.disciplineWarning = true;
      } else if (this.quickActionCommentsAssign.trim().length < 2 && this.quickActionCommentsAssign.trim() === '') {
        this.commentsRequired = 'Comments Required';
      }  else {
        this.quickActionComments = this.quickActionCommentsAssign;
        this.submitLoad = true;
        this.handleFileUpload(actionType, this.IDCAttachment, 'idc');
      }
    },
    checkBlankInfer(dataSet, actionType) {
      this.loadingText = 'Please wait..';
      return this.$store.dispatch('bidTab/checkBlankInfer', dataSet)
        .then((res) => {
          this.loadingText = '';
          return res;
        })
        .catch(error => {
          this.loadingText = '';
          throw error;  
        });
    },
    takeQuickAction(actionType) {
      if (actionType == 'confirmcde' || actionType == 'accept') {
        this.quickActionComments = 'No comment'
      }
      if(this.pageView == 'alt-view'){
          const groupedData = {};
          this.quickData.payLoad.forEach(item => {
              const group = item.req_group;
              const spec = item.tech_spec;
              if (!groupedData[group]) {
                  groupedData[group] = [];
              }
              groupedData[group].push(spec);
          });
          const offers = Array.isArray(this.$props.quickData.offer) ? this.$props.quickData.offer : [this.$props.quickData.offer];
          const promises = offers.map(offer => {
            const payLoadForInfCheck = {
              "data": Object.keys(groupedData).map(group => ({
                  "requirement_group": group,
                  "tech_specs": groupedData[group]
              })),
              "offer_id": this.$props.quickData.parent_offer_id || offer,
              "tag_ids": this.taglists.filter(tag => !tag.tag_disabled).map(tag => tag.tag_uuid)
            };

            return this.checkBlankInfer(payLoadForInfCheck, actionType);
          });
          Promise.all(promises)
            .then((results) => {
              const combinedData = results.map(res => res.data);
              const blankInferData = { total_missing: 0 };
              combinedData.forEach(data => {
                  blankInferData.total_missing += Number(data.data.missing_count) || 0;
              });
              if(blankInferData.total_missing > 0){
                  this.$emit('showBlankInfer', combinedData);
              }else{
                this.saveCollab(actionType)
              }
            })
            .catch(error => {
              this.loadingText = '';
            });
      }else{
        let count = 0
        this.emptyInferenceCount = ''
        const obj = [...new Map(this.$props.quickData.payLoad.map(item => [JSON.stringify(item), item])).values()];
        this.$props.quickData.payLoad.forEach((ci, i) => {
          if (ci.inferenceCol == '' && this.pageView !== 'alt-view') {
            count++
            this.enableDialogWarning = true
            // return count
          }
        })
        this.emptyInferenceCount = count
        if (this.emptyInferenceCount < 1) {
          this.saveCollab(actionType)
        } else if (this.$props.quickData.showEval) {
          this.saveCollab(actionType)
        } else {
          this.filterData()
          this.dialogWarning = true
        }
      }
      // if (this.$props.quickData.technical_description['2__collab'] || this.$props.quickData.technical_description['collab']) {
      //     this.saveCollab(actionType)
      // } else {
      //     this.createCollab(actionType)
      // }
    },
    saveCollab(actionType) {
        let payload = [];
        // techSpecTags based on conditions
        this.setTechSpecTags(actionType);
        // Handle payload
        if (this.category === 'lhs' && actionType === actionNames.ASSIGN) {
            payload = this.prepareLHSCategoryPayload(actionType);
        } else {
            payload = this.prepareGeneralPayload(actionType);
        }
        // specific cases for 'add to tq' and 'assign'
        if ((actionType === 'add to tq' || actionType === 'assign') && !this.showEvalIcon && !this.isPiping) {
            payload = this.mergePayloadForTQAndAssign();
        }
        this.sendPayload(payload);
    },
    setTechSpecTags(actionType) {
        console.log('actionType',actionType);
        if (this.pageView === 'alt-view' && (actionType === 'accept' || actionType === 'confirmcde')) {
            this.techSpecTags = this.taglists.filter(tag => !tag.tag_disabled && tag !== null).map(tag => tag.tag_uuid);
        }
        if (this.$props.quickData.category !== 'packages') {
            this.techSpecTags.push(this.$props.currentTag.tag_uuid); 
        } else {
            this.techSpecTags.push(this.$props.currentTag.sub_package_uuid);
        }
    },
    prepareLHSCategoryPayload(actionType) {
        return this.quickData.payLoad[0].tech_specs.map(val => {
            const commonPayload = {
                offer_id: val.offer_id || '',
                category: this.category,
                req_group: val.requirement_group,
                row_uuid: val.row_uuid,
                tech_spec: val.technical_description,
                action: actionType,
                comment: this.quickActionComments,
                attachments: this.payloadAttachments,
                disciplines: this.selectedDisciplines
            };

            if (this.quickData.category === 'packages') {
                return {
                    ...commonPayload,
                    package_id: this.$route.query.package_id,
                    sub_package_ids: this.selectedTags
                };
            } else {
                return {
                    ...commonPayload,
                    mr_id: this.quickData.payLoad[0]?.mr_id || this.$route.query.mr_id,
                    tag_ids: this.techSpecTags.filter(tag => tag != null)
                };
            }
        });
    },
    prepareGeneralPayload(actionType) {
        const quickDataPayload = JSON.parse(JSON.stringify(this.$props.quickData.payLoad));
        return quickDataPayload.map(val => {
            if (this.$props.callComp !== 'tag-list' && this.$props.quickData.category !== 'packages') {
                val.tag_ids = this.techSpecTags.length ? this.techSpecTags : [this.$props.currentTag.tag_uuid];
            } else {
                val.sub_package_ids = this.selectedTags.length ? this.selectedTags : [this.$props.currentTag.sub_package_uuid];
            }
            val.action = actionType;
            val.category = this.category;
            val.comment = this.quickActionComments;
            val.attachments = this.payloadAttachments;
            val.disciplines = this.selectedDisciplines;
            
            if (actionType === 'add to tq') {
                this.handleAddToTQ(val, quickDataPayload);
            }

            return val;
        });
    },
    handleAddToTQ(val, quickDataPayload) {
        const selectedVendors = this.selectedOffers.filter(x => !quickDataPayload.some(item => item.offer_id === x));
        selectedVendors.push(val.offer_id);
        val.default_id = val.offer_id;
        val.offer_id = selectedVendors;
    },
    mergePayloadForTQAndAssign() {
        let tqpayload = {};
        let tagIds = [];
        this.$props.quickData.payLoad.forEach(val => {
            tqpayload = { ...tqpayload, ...val };
            tagIds.push(...val.tag_ids.map(tg => tg));
        });
        tqpayload.tag_ids = tagIds;
        return [tqpayload];
    },
    sendPayload(payload) {
        this.$store.dispatch('bidTab/setLoading', true)
        this.updateCellData = this.cellData;
        axios.post(`${process.env.VUE_APP_API_MSD}/quickaction/bulk`, payload).then(res => {
            this.handleResponse(res, payload);
        }).catch((err) => {
            this.handleError(err);
        });
    },
    handleResponse(res, payload) {
        this.emptyInferenceCount = '';
        if (res.data.missed_data && typeof res.data.missed_data === 'object' && Object.keys(res.data.missed_data).some(key => Array.isArray(res.data.missed_data[key]) && res.data.missed_data[key].length > 0)) {
            this.warningAlert = true;
            this.warningAlertMessage = res.data.missed_data;
        }
        this.successAlert = true;
        this.successMessage = 'Action Saved';
        setTimeout(() => {
            this.successAlert = false;
            this.warningAlert = false;
            if(this.pageView === 'alt-view'){
              this.$emit('updateBulkQuickAction', res);
            }else{
              this.$parent.showBulkDelete = false;
              this.$emit('updateBulkQuickAction', payload, res);
            }
        }, 5000);
        if (this.$props.callComp === 'tag-list') {
            this.$emit('updateList', res.data.data);
        }
        if (this.$props.callComp === 'detail-compare') {
            this.$parent.resetPayloadForBulk();
        }
        this.closeQuickActionsPoput();
    },
    handleError(err) {
        this.errorAlert = true;
        this.errorMessage = err.response.data.message;
        setTimeout(() => {
            this.errorAlert = false;
        }, 3000);
    },
    lhsBulkUpdate(actionType, typeOfUpdate) {
      if (this.lhsBulkUpdatedValue.length >= 1 || typeOfUpdate == 'Update as a blank' || actionType == 'delete') {
        this.submitLoad = true
        this.$props.quickData.payLoad.forEach(val => {
          if (!this.$route.query.package_id) {
            if (this.techSpecTags.length && this.pageView !== 'alt-view') {
              this.techSpecTags.push(this.$props.currentTag.tag_uuid)
              val.tag_ids = this.techSpecTags
            }else if(this.pageView === 'alt-view'){
              val.tag_ids = this.techSpecTags
              if(actionType === 'delete'){
                val.tag_ids = [this.taglists[0].tag_uuid]
              }
            }else{
              val.tag_ids = [this.$props.currentTag.tag_uuid]
            }
          }
          val.action = actionType
          const obj = [...new Map(val.tech_specs.map(item => [JSON.stringify(item), item])).values()];
          if (typeOfUpdate == 'Update as a blank') {
            this.lhsBulkUpdatedValue = ''
          } if (actionType == 'update') {
            obj.forEach((inj, i) => {
              inj.value = this.lhsBulkUpdatedValue
              inj.remarks = this.techspecRemarks
            })
          }
        })
        if (this.$route.query.package_id) {
          if(this.selectedTags.length > 0){
            this.$props.quickData.payLoad[0].sub_package_ids = this.selectedTags
          }else{
            this.$props.quickData.payLoad[0].sub_package_ids = [this.$props.currentTag.sub_package_uuid]
          }
        }
        axios.post(`${process.env.VUE_APP_API_MSD}/mr/row/bulk`, this.$props.quickData.payLoad[0])
          .then(res => {
              this.techSpecTags = []
              this.lhsDialogWarning = false
              this.successAlert = true
              if(actionType !== 'delete'){
                this.$store.dispatch('bidTab/setLoading', true)
              }
              if(actionType == 'delete'){
                this.successMessage = 'Successfully deleted'
              }else {
                this.successMessage = 'Successfully updated'
              }
              if (res.data.missed_data && typeof res.data.missed_data === 'object' && 
                Object.keys(res.data.missed_data).some(key => 
                Array.isArray(res.data.missed_data[key]) && res.data.missed_data[key].length > 0)) {
                  this.warningAlert = true
                  this.warningAlertMessage = res.data.missed_data
              } 
              setTimeout(() => {
                this.submitLoad = false
                this.successAlert = false
                if (this.pageView === 'alt-view') {
                    this.$emit('updateBulkQuickAction', res.data, null, actionType);
                }
                else if(actionType == 'delete'){
                  this.$parent.getRequirementGroup();
                } 
                else {
                    this.$emit('updateBulkQuickAction', this.$props.requirementGroupBulk)
                }
                this.$parent.showBulkDelete = false;
                this.successMessage = ''
                this.warningAlert = false
                this.lhsBulkDeleteDialog = false
                this.closeQuickActionsPoput()
              }, 5000)
          }).catch((err) => {
            this.submitLoad = false
            this.techSpecTags = []
            this.errorAlert = true
            this.successMessage = ''
            this.errorMessage = err.response.data.message
            setTimeout(() => {
              this.errorAlert = false
            }, 3000)
          })
      } else {
        this.lhsDialogWarning = true
        this.editSpecificationPopout = false
      }
    },
    getTagForEval() {
      this.tagEval = true
      this.cellData = this.$props.quickData
      this.$emit('tagListPopupActive')
      this.$parent.showBulk = false
    },
    close(val, msg) {
      this.tagEval = false
      if (val == 'tag') {
        this.$emit('tag-close', this.cellData, msg)
      }
      this.showPopup = false

    },
  }
}
</script>
<style lang="scss">

.warning-list {
  .mr-list-item {
    border-radius: 8px;
    margin: 2px 0px;
  }
}

.quickactionspoput .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)>.v-input__control>.v-input__slot {
  box-shadow: none;
  border: 1px solid #ccc;
  min-height: 35px;
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
}

.quickactionspoput .v-text-field--filled.v-input--dense.v-text-field--single-line .v-label {
  top: 7px;
}

.quickactionspoput label.v-label.theme--light {
  font-size: 14px;
}

.quickactionspoput span.v-chip__content {
  max-width: 130px !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block !important;
  padding-right: 5px;
  line-height: 24px;
}

.quickactionspoput button.v-icon.notranslate.v-chip__close.v-icon--link.v-icon--right.mdi.mdi-close-circle.theme--light {
  position: absolute;
  right: 7px;
  top: 3px;
}

.v-menu__content {
  z-index: 1234567890 !important;
}

.userdashboard {
  .sticky {
    z-index: 0 !important;
  }
}
</style>
<style lang="scss" scoped>
.v-tooltip__content {
  z-index: 999999999 !important;
}

.bulkquickactions {
  position: fixed;
  left: 0;
  top: 30%;
  background: #000026;
  border-radius: 0px 20px 20px 0px;
  padding: 5px 3px;
  z-index: 1;

  .v-btn--icon.v-size--default {
    display: block;
    width: 30px;
    height: 30px;
    margin: 6px 0px;

    .v-btn__content {
      .v-icon {
        font-size: 30px;
        height: 30px;
        width: 30px;
        text-indent: initial;
      }
    }
  }
}

.discipline-dropdown {
  max-height: 35px;
}
.quickactionspoput {
  padding: 15px 20px 5px 20px;
  width: 320px;
  overflow: overlay;

  h1 {
    font-size: 14px;
    font-weight: bold;

    .v-icon {
      font-size: 22px;
    }
  }

  textarea {
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 3px;
  }

  select {
    box-shadow: none;
    border: 1px solid #ccc;
    min-height: 35px;
    border-radius: 3px;
    /*color: #8c8c8c;*/
    padding: 0 5px;
    width: 100%;
    -moz-appearance: none;
    -webkit-appearance: auto;
    margin-bottom: 10px;
  }

  .v-btn {
    text-transform: capitalize;
    letter-spacing: unset;
  }

  .v-input {
    .v-input__control {
      .v-input__slot {
        box-shadow: none !important;

      }
    }
  }
}
</style>
