<template>
  <v-app>
    <router-view/>
<!--     <card class="notifiction-card">
      notifiction
    </card> -->
    <NotificationContainer/>
  </v-app>
</template>

<script>
import NotificationContainer from '@/components/NotificationContainer.vue'
import 'vue-select/dist/vue-select.css'

export default {
  name: 'App',
  components: {
    NotificationContainer
  },
  mounted(){
  // this.$toast.success("MR extract has been completed",{
  //           position: "bottom-right",
  //         });
  // setTimeout(() => {
  //   console.log('launcher ====================> ', document.getElementById('launcher').style.zIndex = 99);
  // }, 2000)
}
}

</script>

<style lang="scss">
  // Import Main styles for this application
  @import 'assets/scss/style';
  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  ::-webkit-scrollbar-track {
    background: #fff;
  }
  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 25px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  // #launcher {
  //   left: 0px;
  // }

</style>
