import Vue from 'vue';
import axios from 'axios'

const state = {
    searchResults: {},
    myValue: {},
    query: '',
}

const mutations = {
    SET_SEARCH_RESULTS(state, searchResults) {
        Vue.set(state, 'searchResults', searchResults);
    },
    updateSearchFilters(state, newValue) {
        state.myValue = newValue;
    },
    searchQuery(state, newQuery) {
        state.query = newQuery;
    },
    clearData(state) {
        state.myValue = {}
        state.query = ''
    }
}

const actions = {
    setSearchQuery({ commit }, query) {
        commit('searchQuery', query);
    },
    setSearchFilters({ commit }, newValue) {
        commit('updateSearchFilters', newValue);
    },
    async getSearchResults({ commit }) {
        let payload = {
            query: state.query,
            vendor_name: state.myValue.vendor_name,
            item_name: state.myValue.item_name,
            project_name: state.myValue.project_name,
            created_at_start: state.myValue.created_at_start,
            created_at_end: state.myValue.created_at_end,
            page: state.myValue.page,
            per_page: 10,
        }
        const searchResults = await axios.post(`${process.env.VUE_APP_API_SEARCH}/search`, payload);
        commit('SET_SEARCH_RESULTS', searchResults.data.results);
        state.myValue = ''
    },
    getFullSnippet({ state }, param) {
        return axios.post(`${process.env.VUE_APP_API}/offer/full_snippet`, param);
    }
}

const getters = {
    getSearchResults() {
        return state.searchResults
    },
    getSearchQuery(){
        return state.query
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}