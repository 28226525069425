import Vue from 'vue'
import Router from 'vue-router'
import axios from 'axios'


// Containers
const TheContainer = () => import('@/containers/TheContainer')

// Views
const AdminAuthLayout = () => import('@/views/AdminAuthLayout')
const Login = () => import('@/views/Login')
const Acs = () => import('@/views/Acs')
const Page403 = () => import('@/views/pages/page403')
const Register = () => import('@/views/Register')
const CreatePassword = () => import('@/views/CreatePassword')
const ForgotPassword = () => import('@/views/ForgotPassword')
const ResetPassword = () => import('@/views/ResetPassword')
const Dashboard = () => import('@/views/Dashboard')
const EditProfile = () => import('@/views/profile/EditProfile')
const ManageUser = () => import('@/views/profile/ManageUser')
const ConsumptionReport = () => import('@/views/profile/ConsumptionReport')
const AddUser = () => import('@/views/profile/AddUser')
const ProjectListing = () => import('@/views/projects/ProjectListing')
const CreateProject = () => import('@/views/projects/CreateProject')
const ItemListing = () => import('@/views/Item Listing/ItemListing')
const AddItem = () => import('@/views/projects/AddItem')
const OfferListing = () => import('@/views/projects/OfferListing')
const AddOffer = () => import('@/views/projects/AddOffer')
const MRExtract = () => import('@/views/MR Extract/MRExtract')
const MRExtractPackage = () => import('@/views/MR Extract/MRExtractPackage')
const MRandOffer = () => import('@/views/Offer Evaluation/OfferEvaluation')
const OfferEvaluationPackage = () => import('@/views/Offer Evaluation/OfferEvaluationPackage')
const OfferComparison = () => import('@/views/projects/OfferComparison')
const DetailedComparison = () => import('@/views/Detail Offer Evaluation/DetailedComparison')
const DetailedPackageComparison = () => import('@/views/Detail Offer Evaluation/DetailComparePackage')
const RevisionComparison = () => import('@/views/Revision Comparison/RevisionComparison')
const RevisionPackageComparison = () => import('@/views/Revision Comparison/RevisionComparePackage')
const TQListing = () => import('@/views/projects/TQListing')
const TQListingOld = () => import('@/views/projects/TQListingOld')
const PrcTQListing = () => import('@/views/PRC/PrcTQListing')
const PrcEnqListing = () => import('@/views/PRC/PrcEnqListing')
const DrawingComparison = () => import('@/views/projects/DrawingComparison')
const ProjectUserListing = () => import('@/views/projects/ProjectUserListing')
const AddMember = () => import('@/views/projects/AddMember')
const RequisitionUserListing = () => import('@/views/Item Listing/RequisitionUserListing')
const MoreInsights = () => import('@/views/MoreInsights');
const profileLayout = () => import('@/views/profile/profileLayout')
const ManageLogo = () => import('@/components/manageLogo');
const IDCListing = () => import('@/views/IDC');
const bulkMaterialMR = () => import('@/views/Bulk_Material/bidtab/');
const bulkMaterialOffer = () => import('@/views/Bulk_Material/offer/');
const bulkMaterialVendor = () => import('@/views/Bulk_Material/vendor/');

const bidTabLHS= () => import('@/views/AlternateView/LHS/');
const bidTabRHS= () => import('@/views/AlternateView/RHS/');
const bidTabRevisionCompare= () => import('@/views/AlternateView/RevisionCompare/');
const bidTabVendorCompare= () => import('@/views/AlternateView/VendorCompare/');

// SearchReference
const SearchResults = () => import('@/views/Search/Results')
const ManageNotifications = () => import('@/views/profile/ManageNotification')
// Vendor view
const Vendor = () => import('@/views/Vendor/vendor')
const vendorContainer = () => import('@/views/Vendor/vendorContainer')
const VendorAuth = () => import('@/views/Vendor/VendorAuth')
const VendorEnqDetails = () => import('@/views/Vendor/VendorEnqDetails')

import store from '../store'
Vue.use(Router)


const router = new Router({
  mode: 'history', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  routes: [
    {
      path: '/vendor',
      redirect: 'vendorAuth',
      component: Vendor,
      children: [
        {
          path: '',
          name: 'vendorAuth',
          component: VendorAuth
        },
      ]
    },
    {
      path: '/vendor',
      redirect: 'vendor',
      component: vendorContainer,
      children: [
        {
          path: 'VendorEnqDetails',
          name: 'VendorEnqDetails',
          component: VendorEnqDetails
        },
        {
          path: 'vendorenqdetails',
          name: 'VendorEnqDetails',
          component: VendorEnqDetails
        },
      ]
    },
    {
      path: '/',
      redirect: '/login',
      component: AdminAuthLayout,
      children: [
        {
          path: 'login',
          name: 'Login',
          component: Login
        },
        {
          path: 'acs',
          name: 'Acs',
          component: Acs
        },
        {
          path: 'register',
          name: 'Register',
          component: Register
        },
        {
          path: 'createpassword',
          name: 'Create Password',
          component: CreatePassword
        },
        {
          path: 'forgotpassword',
          name: 'Forgot Password',
          component: ForgotPassword
        },
        {
          path: 'resetpassword',
          name: 'Reset Password',
          component: ResetPassword
        },
      ]
    },
    {
      path: '/',
      redirect: '/dashboard',
      name: 'Dashboard',
      component: TheContainer,
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          path: 'dashboard',
          name: 'Dashboard',
          component: Dashboard
        },
        {
          path: '/page-403',
          name: 'Access Denied',
          component: Page403
        },
        {
          path: 'moreinsights',
          name: 'More Insights',
          component: MoreInsights
        },
        {
          path: 'results',
          name: 'Search Results',
          component: SearchResults
        },
        {
          path: 'profile',
          redirect: '/profile/editprofile',
          name: 'Profile',
          component: profileLayout,
          children: [
            {
              path: 'editprofile',
              name: 'Edit Profile',
              component: EditProfile,
            },
            {
              path: 'manageuser',
              name: 'Manage User',
              component: ManageUser,
            },
            {
              path: 'reports',
              name: 'Usage Reports',
              component: ConsumptionReport,
            },
            {
              path: 'managelogo',
              name: 'Manage Logo',
              component: ManageLogo,
            },
            {
              path: 'notification',
              name: 'Manage Notifications',
              component: ManageNotifications,
            }
          ]
        },
        {
          path: '/adduser',
          name: 'Add user',
          component: AddUser
        },
        {
          path: 'projects',
          redirect: '/projects/listing',
          name: 'Projects',
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: 'listing',
              name: '',
              component: ProjectListing,
            },
            {
              path: 'createproject',
              name: 'Create Project',
              component: CreateProject,
            },
            {
              path: 'itemlisting',
              name: 'Bid tab listing',
              component: ItemListing,
            },
            {
              path: 'additem',
              name: 'Add Item',
              component: AddItem,
            },
            {
              path: 'offerlisting',
              name: 'Offer Listing',
              component: OfferListing,
            },
            {
              path: 'addoffer',
              name: 'Add Offer',
              component: AddOffer,
            },
            {
              path: 'mrextract',
              name: 'View Bid tab (LHS) – Default',
              component: MRExtract,
            },
            {
              path: 'mrextractpackage',
              name: 'View Bid tab (LHS) Packages',
              component: MRExtractPackage,
            },
            {
              path: 'mrandoffer',
              name: 'Offer Evaluation',
              component: MRandOffer,
            },
            {
              path: 'mrandofferpackage',
              name: 'Offer Evaluation Packages',
              component: OfferEvaluationPackage,
            },
            {
              path: 'offercomparison',
              name: 'Offer Comparison',
              component: OfferComparison,
            },
            {
              path: 'detailedcomparison',
              name: 'Detailed Comparison',
              component: DetailedComparison,
            },
            {
              path: 'detailedpackagecomparison',
              name: 'Detailed Comparison',
              component: DetailedPackageComparison,
            },
            {
              path: 'revisioncompare',
              name: 'Revision Comparison',
              component: RevisionComparison,
            },
            {
              path: 'revisionpackagecompare',
              name: 'Revision Comparison Package',
              component: RevisionPackageComparison,
            },
            {
              path: 'tqlisting',
              name: 'TQ Listing',
              component: TQListing,
            },
            {
              path: 'tqlistingold',
              name: 'TQ Listing Old',
              component: TQListingOld,
            },
            {
              path: 'prctqlisting',
              name: 'TQ Listing (Published)',
              component: PrcTQListing,
            },
            {
              path: 'prclisting',
              name: 'Procurement Enquiries Listing',
              component: PrcEnqListing
            },
            {
              path: 'drawingcomparison',
              name: 'Drawing Comparison',
              component: DrawingComparison,
            },
            {
              path: 'members',
              name: 'Project Overview',
              component: ProjectUserListing,
            },
            {
              path: 'addmember',
              name: 'Add member',
              component: AddMember,
            },
            {
              path: 'requisitionuserlisting',
              name: 'Bid tab Overview',
              component: RequisitionUserListing,
            },
            {
              path: 'idclisting',
              name: 'IDC Listing(LHS)',
              component: IDCListing,
            },
            {
              path: 'idclisting',
              name: 'IDC Listing(RHS)',
              component: IDCListing,
            },
            {
              path: 'bulkMR',
              name: 'BulkMR',
              component: bulkMaterialMR,
            },
            {
              path: 'bulkOffer',
              name: 'BulkOffer - Offer page',
              component: bulkMaterialOffer,
            },
            {
              path: 'bulkVendor',
              name: 'bulkVendor',
              component: bulkMaterialVendor,
            },
            {
              path: 'bidtablhs',
              name: 'View Bid tab (LHS) - Alternate View',
              component: bidTabLHS,
            },
            {
              path: 'bidtabrhs',
              name: 'Offer Evaluation - Alternate View',
              component: bidTabRHS,
            },
            {
              path: 'bidTabRevisionCompare',
              name: 'Revision Compare - Alternate View',
              component: bidTabRevisionCompare,
            },
            {
              path: 'bidtabvendorcompare',
              name: 'Vendor Compare - Alternate View',
              component: bidTabVendorCompare,
            },
          ]
        },
      ]
    },
  ]
})

// const bidTabRHS= () => import('@/views/AlternateView/RHS/');
// const bidTabRevisionCompare= () => import('@/views/AlternateView/RevisionCompare/');
// const bidTabVendorCompare= () => import('@/views/AlternateView/VendorCompare/');
axios.interceptors.response.use(
  function(response) {
    // Any status code that lies within the range of 2xx causes this function to trigger
    return response;
  }, 
  function(error) {
    // Any status codes that fall outside the range of 2xx cause this function to trigger
    if (error.response && error.response.status === 403) {
      // Redirect to the specified route
      // prompt('You do not have access to this page!')
      router.push('/page-403');  // Adjust this route as necessary
    }
    return Promise.reject(error);
  }
);
router.beforeEach((to, from, next) => {
  console.log('before route => ', to , from, next);
  let loggedIn = JSON.parse(localStorage.getItem('user'))
  let requiresAuth = to.matched.some(record => record.meta.requiresAuth)

  if (requiresAuth && !loggedIn) {
    // console.log('login req => ', requiresAuth, loggedIn);
    // sessionStorage.setItem('pathToLoadAfterLogin', window.location.pathname + window.location.search)
    let loginPath = window.location.pathname + window.location.search
    localStorage.setItem('pervloginPath', loginPath)
    // next({ path: '/login'})
    next({ name: 'Login', query: { from: loginPath } });
  } else if (!requiresAuth && loggedIn) {
    next('dashboard')
  } else {
    next();
  }
})

export default router
